import React, { useState, useEffect } from "react";
import AppMainLayout from "../../../layouts/app-main-layout"
import Button from '@mui/material/Button';
import { Redirect } from "react-router-dom";
import classNames from "classnames"
import { GET_AFISHA_INFO, AFISHA_SAVE_DETAILS, ADMIN_EMAIL } from "../../../helpers/constants"
import axiosFetch from "../../../helpers/axios"
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import CookieHelper from "../../../helpers/cookie-helper";

import "./styles.scss"

function Ads() {

    const [isFetching, setIsFetching] = useState(false)
    const [afishaInfo, setAfishaInfo] = useState([])
    const [isRefetch, setIsRefetch] = useState(true)

    const oAuth = CookieHelper("get", "oauth");

    useEffect(() => {

        if (isRefetch) {
            setIsRefetch(false)
            setIsFetching(true)
            const formData = new FormData()

            axiosFetch(GET_AFISHA_INFO, formData).then(res => {
                setAfishaInfo(res)
                setIsFetching(false)
            })
        }

    }, [isRefetch])

    const onChange = (id, state, value) => {
        setAfishaInfo(prevData =>
            prevData.map(item =>
                item.id === id
                    ? {
                        ...item,
                        [state]: value,
                        ...(state === "cancelled" && Number(value) === 1 ? { reason: "" } : {})
                    }
                    : item
            )
        );
    }


    const saveAfisha = (afishaId) => {
        const formData = new FormData();
        //.format("DD/MM/YYYY HH:mm")
        formData.append("afishaId", afishaInfo[afishaId].id);
        formData.append("date", dayjs(afishaInfo[afishaId].date));
        formData.append("title", afishaInfo[afishaId].title);
        formData.append("description", afishaInfo[afishaId].description);
        formData.append("visible", afishaInfo[afishaId].visible);
        formData.append("cancelled", afishaInfo[afishaId].cancelled);
        formData.append("reason", afishaInfo[afishaId].reason);
        formData.append("afishaTitle", afishaInfo[afishaId].afishaTitle);
        formData.append("translation", afishaInfo[afishaId].translation);
        axiosFetch(AFISHA_SAVE_DETAILS, formData).then(res => {
            console.log("res", res)
            setIsRefetch(true)
        })
    }


    const renderAFishaList = () => {
        const content = (afishaInfo || []).map((item, index) => {
            const { id, date, title, description, visible, cancelled, reason, afishaTitle, translation } = item

            return (
                <div className="col-md-4" key={index}>

                    <div
                        className={classNames({
                            'home-post': true,
                        })}
                    >
                        <div className={"afisha-title"}>


                            <TextField
                                id="outlined-basic-1"
                                label="День недели"
                                fullWidth
                                variant="outlined"
                                value={title}
                                onChange={(e) => onChange(id, "title", e.target.value)}
                            /> <br /><br />

                            <TextField
                                id="outlined-basic-1"
                                label="Ссылка на трансляцию"
                                fullWidth
                                variant="outlined"
                                value={translation}
                                onChange={(e) => onChange(id, "translation", e.target.value)}
                            /><br /> <div className="clear-translation" onClick={() => onChange(id, "translation", "")}>Очистить</div> 

                            <TextField
                                id="outlined-basic-1"
                                label="Заголовок"
                                fullWidth
                                variant="outlined"
                                value={afishaTitle}
                                onChange={(e) => onChange(id, "afishaTitle", e.target.value)}
                            /> <br /><br />

                            <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                                adapterLocale={"ru"}
                            >
                                <DatePicker
                                    label={"Дата события"}
                                    value={dayjs(date)}
                                    onChange={(e) => onChange(id, "date", e)}
                                    format={"DD/MM/YYYY"}
                                />
                            </LocalizationProvider>
                            <br /><br />

                            <TextField
                                id="outlined-basic-1"
                                label="Заголовок"
                                fullWidth
                                variant="outlined"
                                value={description}
                                multiline
                                minRows={10}
                                onChange={(e) => onChange(id, "description", e.target.value)}
                            /> <br /><br />

                            <FormControl className="afisha-visible">

                                <RadioGroup
                                    aria-labelledby="demo-row-radio-buttons-group-label"
                                    name="row-radio-buttons-group"
                                    // value={Number(visible) === 1}
                                    onChange={(e) => onChange(id, "visible", e.target.value)}
                                >
                                    <FormControlLabel value={1} control={<Radio checked={Number(visible) === 1} />} label="Показать" />
                                    <FormControlLabel value={0} control={<Radio checked={Number(visible) === 0} />} label="Скрыть" />
                                </RadioGroup>
                            </FormControl>

                            <FormGroup className="afisha-cancelled">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={Number(cancelled) === 1}
                                            onChange={(e) => onChange(id, "cancelled", e.target.checked ? 1 : 0)}
                                        />
                                    }
                                    label="Событие отменилось"
                                />
                            </FormGroup>

                            {Number(cancelled) === 1 && <TextField
                                id="outlined-basic-1"
                                label="Причина переноса"
                                fullWidth
                                variant="outlined"
                                value={reason}
                                onChange={(e) => onChange(id, "reason", e.target.value)}
                            />}
                            <br /><br />
                        </div>
                        <Button
                            onClick={() => saveAfisha(index)}
                            variant="contained"
                            fullWidth
                            disabled={false}
                        >
                            Сохранить
                        </Button>
                        {/* <div className={"afisha-date"}>{date}</div> */}
                    </div>
                </div>
            )
        })

        return content
    }



    return (
        <AppMainLayout>
            {oAuth?.email !== ADMIN_EMAIL && <Redirect to="/" />}
            <div className="row afisha-container">

                <div
                    className={classNames({
                        'col-md-12': true,
                        'no-content': isFetching
                    })}
                >
                    {isFetching ? <CircularProgress size={58} /> : afishaInfo.length > 0 && renderAFishaList()}
                </div>
            </div>
        </AppMainLayout >
    );
}


export default Ads